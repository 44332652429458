import type { FromModel } from "@/__main__/data-model.mjs";
import createModel, { Silent } from "@/__main__/data-model.mjs";
import { paymentPlanModel } from "@/feature-wallet/models/payment-plans.mjs";

export const PremiumPricingModel = createModel({
  data: {
    paymentPlan: paymentPlanModel,
    isEligibleForFreeTrial: Silent(Boolean),
  },
}).transform(
  {
    ...paymentPlanModel,
    isEligibleForFreeTrial: Boolean,
  },
  (data) => {
    const { paymentPlan, isEligibleForFreeTrial } = data.data;

    return { ...paymentPlan, isEligibleForFreeTrial };
  },
);

export type PremiumPricing = FromModel<typeof PremiumPricingModel>;
