import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { getProPricing } from "@/feature-subscriber/api.mjs";
import { PremiumPricingModel } from "@/feature-subscriber/model-pro-pricing.mjs";
import {
  getPaymentPlans,
  getUserPaymentMethods,
  getUserSubscriptions,
} from "@/feature-wallet/api.mjs";
import { PaymentPlansModel } from "@/feature-wallet/models/payment-plans.mjs";
import { UserPaymentMethodsModel } from "@/feature-wallet/models/user-payment-methods.mjs";
import { UserSubscriptionsModel } from "@/feature-wallet/models/user-subscriptions.mjs";
import { getDefaultUserPaymentMethod } from "@/feature-wallet/utils/default-user-payment-method.mjs";
import { devError } from "@/util/dev.mjs";

const DEFAULT_PAY_METHOD = "STRIPE" as const;

function fetchData() {
  async function fetchUserSideEffects() {
    await initAuth();

    const sideLoadPromises = [];

    const user = readState.user;
    const bearerToken = await getBearerToken();
    const isLoggedIn = Boolean(user && bearerToken);
    const requestOptions = { headers: { Authorization: bearerToken } };

    if (!isLoggedIn) return;

    await getData(
      getUserPaymentMethods(),
      UserPaymentMethodsModel,
      ["wallet", "paymentProviders"],
      requestOptions,
    );

    const defaultPaymentMethod = getDefaultUserPaymentMethod({
      paymentProviders: readState.wallet.paymentProviders,
      settings: readState.settings,
    });

    sideLoadPromises.push(
      getData(
        getUserSubscriptions(),
        UserSubscriptionsModel,
        ["wallet", "subscriptions"],
        requestOptions,
      ),
      getData(
        getProPricing({
          includeUserInfo: true,
          paymentMethodId: defaultPaymentMethod?.id,
          providerType:
            defaultPaymentMethod?.providerType ?? DEFAULT_PAY_METHOD,
        }),
        PremiumPricingModel,
        ["subscriber", "pricing"],
        requestOptions,
      ),
    );

    return Promise.all(sideLoadPromises);
  }

  fetchUserSideEffects().catch((error) => {
    devError("ERROR LOADING PRO USER INFO", error);
  });

  return Promise.all([
    getData(
      getPaymentPlans(),
      PaymentPlansModel,
      ["wallet", "paymentPlans"],
      {},
    ),
    getData(
      getProPricing({ providerType: DEFAULT_PAY_METHOD }),
      PremiumPricingModel,
      ["subscriber", "pricing"],
      {},
    ),
  ]);
}

export default fetchData;
